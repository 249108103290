body {
  margin: 0;
  font-family: "Microsoft YaHei", "PingFang SC", "SimSun", "Hiragino Sans GB",
    Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body .ant-dropdown-menu {
  max-height: 500px;
  overflow: scroll;
}
