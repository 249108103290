.export-and-import-alert-wrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 171px;
    z-index: 9999;
    width: 861px;
    height: 409px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 4.2px 0px rgba(136, 140, 176, 0.25);
    padding: 24px 20px 20px 24px;
    box-sizing: border-box;

    .top-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tabs {
            display: flex;
            width: 260px;
            height: 56px;
            border-radius: 44px;
            background-color: #F1F3F6;
            padding: 4px;
            box-sizing: border-box;
    
            .tab {
                width: 124px;
                height: 48px;
                border-radius: 44px;
                text-align: center;
                line-height: 48px;
                font-size: 20px;
                color: #949494;
                background-color: #F1F3F6;
                cursor: pointer;
    
                &:nth-of-type(1) {
                    margin-right: 4px;
                }
    
                &:hover {
                    background-color: #E7EAF0;
                }
    
                &.active {
                    color: #fff;
                    background: linear-gradient(#0E8BFF, #0EA8FF);
                }
            }
        }
        .close-icon {
            font-size: 32px;
            color: #A4AEB9;
            font-weight: 300;
            cursor: pointer;
    
            &:hover {
                opacity: 0.6;
            }
        }
    }

    .main-content {
        position: relative;
        width: 821px;
        height: 294px;
        margin-top: 19px;
        border-radius: 12px;
        background: #F8F8F8;
        padding: 14px 14px 10px 14px;
        box-sizing: border-box;
        color: #949494;
        overflow: hidden;
        font-size: 14px;

        .export-content {
            text-align: start;
        }

        .main-content-btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
            color: #fff;
            width: 190px;
            height: 55px;
            border-radius: 40.959px;
            font-size: 18px;
            text-align: center;
            line-height: 55px;
            cursor: pointer;
            background: linear-gradient(315deg, #8ACDFD 4.72%, rgba(15, 147, 255, 0.00) 71.67%), #0F93FF;
        }
    }
}