.middle-adv-area {
    position: absolute;
    top: 0;
    width: 100%;
    height: 56px;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;

    .float-icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 24px;
    }
}