.navigator {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #001529;
    justify-content: space-between;

    .left-area {
        display: flex;
        width: 251px;
        height: 48px;
        padding: 8px 16px;
        box-sizing: border-box;
        align-items: center;

        img {
            width: 33px;
            height: 33px;
        }

        .name {
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;
            margin: 0 12px;
            flex-shrink: 0;
            color: #fff;
        }

        .box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 80px;
            height: 25.5px;
            border-radius: 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.68);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 40%, rgba(255, 255, 255, 0.8) 40%, transparent);
            div {
                width: 79px;
                height: 24.5px;
                border-radius: 4px;
                line-height: 24.5px;
                text-align: center;
                font-weight: 700;
                user-select: none;
                background: linear-gradient(180deg, #585858 0%, rgba(88, 88, 88) 100%);
            }
        }
    }

    .right-area {
        display: flex;
        height: 100%;
        align-items: center;

        .feature-area {
            display: flex;
        }

        .line {
            width: 1px;
            height: 30px;
            background-color: rgba(255, 255, 255, 0.24);
        }

        .login-area {
            margin: 0 18px;

            .avatar {
                margin: 0 8px 0 12px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }

            .name {
                font-size: 14px;
                margin-right: 12px;
                color: #fff;
                user-select: none;
                cursor: pointer;
            }
        }
    }
}