@font-face {
  font-family: "JD";
  src: url("https://wq.360buyimg.com/data/ppms/others/JDZH_Regular.ttf")
    format("truetype");
}

.hot-area-edit-wrap {
  width: 100%;
  height: 100%;
  font-size: 12px;
  .hot-area-target {
    flex-shrink: 0;
    .hot-item {
      position: absolute;
      width: 100px;
      height: 100px;
      background-color: rgba(3, 116, 255, 0.701);

      .price {
        position: absolute;
        user-select: none;
        cursor: move;
        font-family: JD;
        line-height: normal;
        // padding: 1px 2px;
      }
    }
  }

  .hot-area-config-module {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #ececec;
  }
}
