.draw-content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
    overflow: hidden;

    .z-index-1st {
        z-index: 30;
    }

    .z-index-2nd {
        z-index: 29;
    }

    .move-item {
        width: 100px;
        height: 100px;
    }

    .drag-bg {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: grab;
    }

    .scale-content {
        position: absolute;
        background-color: #fff;
        left: calc(50% - 187.5px);
        top: calc(50% - 333.5px);
        width: 375px;
        height: 667px;
        // transition: transform 100ms;
        overflow: hidden;

        .moveable-content-wrap {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}