.login-wrap {
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f5;
  position: relative;
  overflow: hidden;

  .title {
    img {
      width: 101px;
      height: 101px;
    }

    span {
      user-select: none;
      font-size: 50px;
      font-weight: 800;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .input-area {
    position: absolute;
    top: 176px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    overflow: hidden;
    width: 600px;
    font-size: 14px;
    color: #1890ff;
    .tab {
      padding-top: 12px;
      box-sizing: border-box;
      height: 46px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      .line {
        width: 28px;
        height: 2px;
        background: #1890ff;
      }
    }
    .check-box {
      float: left;
      font-size: 14px;
    }

    .submit {
      width: 360px;
      height: 40px;
      background-color: #1890ff;
      text-align: center;
      line-height: 40px;
      color: #fff;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .big-logo {
    width: 768px;
    height: 768px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50%;
  }
}
