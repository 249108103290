.App {
  text-align: center;

  a {
    text-decoration: none;
    color: #000;
  }
  /**
  * 全局样式 global.css
  */
  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .justify-center {
    display: flex;
    justify-content: center;
  }

  .justify-end {
    display: flex;
    justify-content: flex-end;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }

  .justify-around {
    display: flex;
    justify-content: space-around;
  }

  .items-start {
    display: flex;
    align-items: flex-start;
  }

  .items-center {
    display: flex;
    align-items: center;
  }

  .items-end {
    display: flex;
    align-items: flex-end;
  }

  .highest-layer {
    position: absolute;
    z-index: 99999;
  }

  .sec-highest-layer {
    z-index: 99998;
  }

  .trd-highest-layer {
    z-index: 99997;
  }
}
