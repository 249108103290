.left-content-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    z-index: 99;

    .top-area {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #e9e9e9;
        color: #1890FF;
        font-size: 14px;
        font-weight: 400;

        img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }

        .choose-wrap {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .title {
                text-align: start;
                width: 112px;
                overflow: hidden;
                user-select: none;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .plugin-item {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between;
            width: 184px;
            height: 54px;
            border-radius: 4px;
            background-color: #f0f0f0;
            margin-top: 11px;
            cursor: grab;

            &.blue {
                position: relative;
                background-color: #0E8BFF;
                color: #fff;

                .advert-area {
                    display: none;
                }

                &:hover {
                    background-color: rgb(14, 139, 255, 0.8);

                    .advert-area {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        flex-shrink: 0;
                        top: 0;
                        left: 0;
                        z-index: 30;
                        width: 652px;
                        height: 360px;
                        border-radius: 4px;
                        border: 1px solid #FFF;
                        box-sizing: border-box;
                        overflow: hidden;
                        background: linear-gradient(145deg, #0E8BFF 28.9%, #5DBBFF 92.92%);
                        cursor: auto;
    
                        .top {
                            width: 100%;
                            height: 60px;
                            color: #fff;
                            border-bottom: 1px solid #fff;
                            padding: 0 23px 0px 20px;
                            font-weight: 600;
                            font-size: 14px;
                        }
    
                        .bottom {
                            flex: 1;
                            position: relative;
                            .qr-area {
                                margin-top: 21px;
                                .qr-code {
                                    width: 130px;
                                    height: 114px;
                                    border-radius: 13px;
                                    .qr-img {
                                        width: 112px;
                                        height: 112px;
                                        flex-shrink: 0;
                                        cursor: pointer;
                                    }
                                    .qr-name {
                                        margin-top: 8px;
                                        white-space: nowrap;
                                    }
                                }
                            }
        
                            .tips {
                                width: 100%;
                                margin-top: 58px;
                            }
    
                            .large-qr {
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(9, 9, 9, 0.37);
                                backdrop-filter: blur(5px);
    
                                img {
                                    width: 181px;
                                    height: 184px;
                                    margin-bottom: 9px;
                                }
                            }
                        }
                    }
                }
                }
                

            .name {
                max-width: 133px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 20px;
            }

            .icon {
                width: 21px;
                height: 21px;
                margin-right: 5px;
            }
        }

        .course-btn {
            position: absolute;
            bottom: 13px;
            width: 184px;
            height: 54px;
            border-radius: 4px;
            text-align: center;
            line-height: 54px;
            color: #888;
            font-size: 14px;
            cursor: pointer;
            background: linear-gradient(0deg, #E8E8E8 0%, #E8E8E8 100%), linear-gradient(145deg, #0E8BFF 28.9%, #5DBBFF 92.92%);

            &:hover {
                opacity: 0.6;
            }
        }
    }
}