.right-content-wrap {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .main-content {
        flex: 1;
        overflow: scroll;
    }

    .bottom-area {
        width: 100%;
        height: 78px;
        border-top: 1px solid #ECECEC;
        box-sizing: border-box;

        .button {
            width: 229px;
            height: 40px;
            box-sizing: border-box;
            border: 1px solid #4F48DD;
            border-radius: 6px;
            background: #f0f0f0;
            color: #4F48DD;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }

            .icon {
                transform: rotate(90deg);
            }
        }
    }
    .layer-selector {
        position: absolute;
        z-index: 10000;
        left: -185px;
        bottom: 4px;
        background-color: #fff;
        width: 176px;
        height: 384px;
        border-radius: 6px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

        .title-area {
            height: 52px;
            width: 100%;
            border-bottom: 1px solid #ECECEC;

            .title {
                user-select: none;
                font-size: 14px;
                font-weight: 500;
            }

            .close {
                cursor: pointer;
                width: 22px;
                height: 22px;

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        .layer-area {
            flex: 1;
            width: 100%;
            padding: 18px 0px;
            box-sizing: border-box;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            // overflow: scroll;
            .layer {
                width: 140px;
                height: 33px;
                color: #252525;
                font-size: 12px;
                border-radius: 4px;
                overflow: hidden;
                background-color: #F6F6F6;
                box-sizing: border-box;
                border: 1px solid transparent;

                &:hover {
                    background-color: #EDF1FD;
                    border: 1px solid #655FE1;

                    .icon-area {
                        display: flex;
                    }
                }

                .name {
                    padding: 7px 0px 7px 9px;
                    flex: 1;
                    user-select: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: start;
                }

                .icon-area {
                    // display: none;
                    margin-left: 5px;
                    width: 33px;
                    height: 100%;

                    .delete {
                        width: 13px;
                        height: 13px;
                        color: #655FE1;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .arrow-wrap {
                        margin-left: 7px;
                        width: 12.1px;
                        height: 100%;
                        background-color: #FBFCFF;

                        .box {
                            width: 100%;
                            height: 50%;
                            box-sizing: border-box;
                            cursor: pointer;

                            &:hover {
                                .icon {
                                    opacity: 0.3;
                                }

                                .disabled {
                                    opacity: 1;
                                }
                            }

                            .icon {
                                width: 8px;
                                height: 4px;
                                color: #655FE1;
                            }

                            .disabled {
                                color: #C9C8DE;
                                cursor: not-allowed;
                            }
                        }

                        .box:nth-of-type(1) {
                            border-bottom: 1px solid #EDF1FD;
                        }
                    }
                }
            }
        }

    }
}